body {
  margin: 0;
  font-family: "HK Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  transition: all 0.5s !important;
}

@font-face {
  font-family: "HK Grotesk";
  src: local("HK Grotesk"),
    url("./fonts/HKGroteskWide-SemiBold.otf") format("opentype");
}
